import React from 'react';

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.onRouteChange  = this.props.onRouteChange;
        this.setEmail = this.props.setEmail;
        this.state = {
            email: '',
            password: '',
            name: '',
            error: {
                email: '',
                password: '',
                name: '',
                general_error: ''
            }
        }
    }

    onNameChange = async (event) => {
        await this.setState({name: event.target.value});
    }

    onEmailChange = async (event) => {
        await this.setState({email: event.target.value});
    }

    onPasswordChange = async (event) => {
        await this.setState({password: event.target.value});
    }

    onSubmitSignIn = async () => {
        let status = null;

        await this.setState({error: {
            email: '',
            password: '',
            name: '',
            general_error: ''
          }});

        await fetch('https://regrantdev.com:443/register', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password,
                name: this.state.name
            })
        })
        .then(response => {
            status = response.status
            return response.json()
        })
        .then(async user => {
            if (status !== 400) {
                await this.setEmail(this.state.email);
                await this.onRouteChange('accountValidation');
            }
            else {
                await this.setState({error: {
                    email: user.email,
                    password: user.password,
                    name: user.name,
                    general_error: user.general_error
                }});
            }
        })
        .catch(async err => await this.setState({error: {
            email: '',
            password: '',
            name: '',
            general_error: 'Cannot connect to server'
        }}));
    }

    render() {
        return (
            <React.Fragment>
                <React.Fragment>
                    <div className='white f1 pt1 fw6'>
                        {'PlanitWeb'}
                    </div>
                    <div className='white f3 fw5 pb3'>
                        {'Event Planning Made Easy'}
                    </div>
                </React.Fragment>
                <article className="br3 ba b--black-10 mv4 mw6 shadow-5 center bg-light-gray">
                    <main className="pa4 black-80">
                        <div className="measure">
                            <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                                <label className="f1 fw6 ph0 mh0">Register</label>
                                <div className="mt3">
                                    <label className="db fw6 lh-copy f6" htmlFor="name">Name</label>
                                    <input 
                                        className="pa2 input-reset ba w-100 tc" 
                                        type="text" 
                                        name="name"
                                        title="100 character limit" 
                                        minLength="1"
                                        maxLength="100"  
                                        id="name"
                                        onChange={this.onNameChange}
                                        required
                                    />
                                    <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.name}</label>
                                </div>
                                <div className="mt3">
                                    <label className="db fw6 lh-copy f6" htmlFor="email-address">Email</label>
                                    <input 
                                        className="pa2 input-reset ba w-100 tc" 
                                        type="email" 
                                        name="email-address"
                                        title="255 character limit" 
                                        maxLength="255"  
                                        id="email-address"
                                        onChange={this.onEmailChange}
                                        required
                                    />
                                    <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.email}</label>
                                </div>
                                <div className="mv3">
                                    <label className="db fw6 lh-copy f6" htmlFor="password">Password</label>
                                    <input 
                                        className="b pa2 input-reset ba w-100 tc" 
                                        type="password" 
                                        name="password"  
                                        id="password"
                                        minLength="8"
                                        maxLength="255" 
                                        title="8 to 255 characters"
                                        onChange={this.onPasswordChange}
                                        required
                                    />
                                    <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.password}</label>
                                </div>
                            </fieldset>
                            <React.Fragment>
                                <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
                                    type="submit" 
                                    value="Register" 
                                    onClick={this.onSubmitSignIn}
                                />
                            </React.Fragment>
                            <div className='pt3'>
                                <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.general_error}</label>
                            </div>
                            <div className="lh-copy mt3">
                                <p onClick={async () => await this.onRouteChange('signin')} className="f6 link dim black db pointer">Back to sign in</p>
                            </div>
                        </div>
                    </main>
                </article>
            </React.Fragment>
        );
    }
}

export default Register;