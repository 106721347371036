import React from 'react';

const Signout = ({ onRouteChange }) => {
    return (
        <article className="br3 ba b--black-10 mv4 mw6 shadow-5 center bg-light-gray">
            <main className="pa4 black-80">
                <div className="measure">
                    <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                        <label className="f3 fw6 ph0 mh0">User Logged Out!</label>
                    </fieldset>
                    <React.Fragment>
                        <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib pt2" 
                            type="submit" 
                            value="Return To Home" 
                            onClick={async () => await onRouteChange('home', true)}
                        />
                    </React.Fragment>
                </div>
            </main>
        </article>
    );
}

export default Signout;