import React from 'react';

class EmailValidation extends React.Component {
    constructor(props) {
        super(props);
        this.onRouteChange  = this.props.onRouteChange;
        this.currentEmail = this.props.currentEmail;
        this.updateEmail = this.props.updateEmail;
        this.updateUserEmail = this.props.updateUserEmail;
        this.setUpdateEmail = this.props.setUpdateEmail;
        this.state = {
            isButtonDisabled: false,
            accountCode: '',
            generateCodeText: '',
            error: {
                accountCode: '',
                general_error: ''
            }
        }
    }

    onCodeChange = async (event) => {
        await this.setState({accountCode: event.target.value});
    }

    generateNewCode = async () => {
        let status = null;

        await this.setState({generateCodeText: ''});

        await fetch('https://regrantdev.com:443/updateemail', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                newEmail: this.updateEmail,
                currentEmail: this.currentEmail,
                generateNewCode: true
            })
        })
        .then(response => {
            status = response.status
            return response.json()
        })
        .then(async data => {
            if (status !== 400) {
                await this.setState({generateCodeText: data.generateCodeText});
            }
            else {
                await this.setState({
                    generateCodeText: data.generateCodeText,
                    error: {
                        general_error: data.general_error
                    }
                });
            }
        })
        .catch(async err => await this.setState({
            generateCodeText: '',
            error: {
                general_error: 'Could not generate new code'
            }
        }));
    }

    onSubmitValidation = async () => {
        let status = null;

        await this.setState({
            generateCodeText: '',
            error: {
                accountCode: '',
                general_error: ''
            }
        });

        await fetch('https://regrantdev.com:443/updateemail', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                newEmail: this.updateEmail,
                currentEmail: this.currentEmail,
                account_code: this.state.accountCode,
                generateNewCode: false
            })
        })
        .then(response => {
            status = response.status
            return response.json()
        })
        .then(async user => {
            if (status !== 400) {
                await this.updateUserEmail(this.updateEmail)
                await this.setUpdateEmail('');
                await this.onRouteChange('userAccount');
            }
            else {
                await this.setState({error: {
                    accountCode: user.accountCode,
                    general_error: user.general_error
                }});
            }
        })
        .catch(async err => await this.setState({error: {
            accountCode: '',
            general_error: 'Cannot connect to server'
        }}));
    }

    onGenerateClicked = async (event) => {
        event.preventDefault();
        
        await this.setState({isButtonDisabled: true});        
    
        setTimeout(async () => {
            await this.setState({isButtonDisabled: false})
        }, 3000);
    
        return await this.generateNewCode();
    }

    render() {
        return (
            <article className="br3 ba b--black-10 mv4 mw6 shadow-5 center bg-light-gray">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <label className="f1 fw6 ph0 mh0">Email Validation</label>
                            <div className="">
                                <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
                                    type="submit" 
                                    value="Generate New Code" 
                                    onClick={this.onGenerateClicked}
                                    disabled={this.state.isButtonDisabled}
                                />
                                <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.generateCodeText}</label>
                            </div>
                            <div className="mt3">
                                <label className="db fw6 lh-copy f6" htmlFor="email-address">Account Code</label>
                                <input 
                                    className="pa2 input-reset ba w-100 tc" 
                                    type="text" 
                                    name="code"  
                                    id="validation_code"
                                    maxLength="255"
                                    onChange={this.onCodeChange}
                                    required
                                />
                                <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.accountCode}</label>
                            </div>
                        </fieldset>
                        <div className="">
                            <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
                                type="submit" 
                                value="Submit" 
                                onClick={this.onSubmitValidation}
                            />
                        </div>
                        <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.general_error}</label>
                        <div className="lh-copy mt3">
                            <p onClick={async () => await this.onRouteChange('userAccount')} className="f6 link dim black db pointer">Back to User Account</p>
                        </div>
                    </div>
                </main>
            </article>
        );
    }
}

export default EmailValidation;