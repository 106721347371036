import React from 'react';

const ForgotPasswordEmailSent = ({ onRouteChange }) => {
    return (
        <article className="br3 ba b--black-10 mv4 mw6 shadow-5 center bg-light-gray">
            <main className="pa4 black-80">
                <div className="measure">
                    <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                        <label className="f3 fw6 ph0 mh0">If a matching account was found in our system then an email was sent.</label>
                    </fieldset>
                    <React.Fragment>
                        <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib pt2" 
                            type="submit" 
                            value="Sign In" 
                            onClick={async () => await onRouteChange('signin')}
                        />
                    </React.Fragment>
                </div>
            </main>
        </article>
    );
}

export default ForgotPasswordEmailSent;