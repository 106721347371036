import React from 'react';

class EventSearchBox extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.setSearchField = this.props.setSearchField;
        this.state = {
            searchText: '',
            privateKeySearch: '',
            error: '',
            isButtonDisabled: false 
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSearchChange = async (event) => {
        if(this._isMounted) {
            await this.setState({searchText: event.target.value});
        }
    }

    onPrivateKeyChange = async (event) => {
        if(this._isMounted) {
            await this.setState({privateKeySearch: event.target.value});
        }
    }

    onSubmitSearch = async () => {
        let status = null;
        
        if(this._isMounted) {
            await this.setState({error: ''});
        }

        await fetch('https://regrantdev.com:443/events', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                search: this.state.searchText.toLowerCase(),
                loadEvents: true,
                loadNewEventsID: '',
                requestfromSearch: true,
                requestfromUrl: false,
                privateKeySearch: this.state.privateKeySearch
            })
        })
        .then(response => {
            status = response.status
            return response.json()
        })
        .then(async eventData => {
            if(status !== 400 && eventData !== 'empty') {
                if(this._isMounted) {
                    await this.setSearchField(eventData, this.state.searchText, this.state.privateKeySearch);
                }
            }
            else {
                if(this._isMounted) {
                    if (eventData !== 'empty') {
                        await this.setState({error: eventData});
                        await this.setSearchField([], this.state.searchText, this.state.privateKeySearch);
                    }
                    else {
                        await this.setState({error: 'No events'});
                        await this.setSearchField([], this.state.searchText, this.state.privateKeySearch);
                    }
                }
            }
        })
        .catch(async err => {
            if(this._isMounted) {
                await this.setState({error: 'Cannot connect to server'})
            }
        });
    }

    onSearchClicked = async (event) => {
        event.preventDefault();
        
        if(this._isMounted) {
            await this.setState({isButtonDisabled: true});        
        }
    
        setTimeout(async () => {
            if(this._isMounted) {
                await this.setState({ isButtonDisabled: false })
            }
        }, 1000);
    
        return await this.onSubmitSearch();
    }

    render() {
        return (
            <React.Fragment>
                {
                    window.innerWidth >= 852 ?
                    <div>
                        <div className='center'>
                            <div className='ph1 pt2'>
                                <input 
                                    size="50"
                                    className='w-100 ph3 pv1 ba b--green bg-lightest-blue tc'
                                    type='search' 
                                    placeholder='Search Events'
                                    maxLength="255"
                                    onChange={this.onSearchChange}
                                />
                            </div>
                            <div className='ph1 pt2'>
                                <input 
                                    size="20"
                                    className='w-100 ph3 pv1 ba b--green bg-lightest-blue tc'
                                    type='search' 
                                    placeholder='Private Event Key'
                                    maxLength="255"
                                    onChange={this.onPrivateKeyChange}
                                />
                            </div>
                            <div className='ph1 pt2'>
                                <input className='w-100 b ph4 pv1 input-reset ba b--black bg-washed-yellow pointer f5 dib'
                                    type='submit'
                                    value='Search'
                                    onClick={this.onSearchClicked}
                                    disabled={this.state.isButtonDisabled}
                                />
                            </div>
                        </div>
                        <div className='pa2 center'>
                            <label className='w-100 bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error}</label>
                        </div>
                    </div>
                    :
                    <div>
                        <div className='column'>
                            <div className='center'>
                                <div className='ph1 pt2'>
                                    <input 
                                        size="50"
                                        className='w-100 ph3 pv1 ba b--green bg-lightest-blue tc'
                                        type='search' 
                                        placeholder='Search Events'
                                        maxLength="255"
                                        onChange={this.onSearchChange}
                                    />
                                </div>
                            </div>
                            <div className='center'>
                                <div className='ph1 pt2'>
                                    <input 
                                        size="20"
                                        className='w-100 ph3 pv1 ba b--green bg-lightest-blue tc'
                                        type='search' 
                                        placeholder='Private Event Key'
                                        maxLength="255"
                                        onChange={this.onPrivateKeyChange}
                                    />
                                </div>
                            </div>
                            <div className='center'>
                                <div className='ph1 pt2'>
                                    <input className='w-100 b ph4 pv1 input-reset ba b--black bg-washed-yellow pointer f5 dib'
                                        type='submit'
                                        value='Search'
                                        onClick={this.onSearchClicked}
                                        disabled={this.state.isButtonDisabled}
                                    />
                                </div>
                            </div>
                            <div className='pa2 center'>
                                <label className='w-100 bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error}</label>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default EventSearchBox