import React from 'react';
import Event from './Event';

const EventList = ({ events, onRouteChange, loadViewingEvent, event_images }) => {
    
    return (
        <React.Fragment>
            {
                events.map((event, i) => {
                    return (
                        <Event 
                            key={i} 
                            event={events[i]}
                            onRouteChange={onRouteChange}
                            loadViewingEvent={loadViewingEvent}
                            event_images={event_images}
                        />
                    );
                })
            }
        </React.Fragment>
    );
}

export default EventList