import React from 'react';
import noImage from '../../Tools/noImage.png';

import './Event.css'

const Event = ({ event, onRouteChange, loadViewingEvent, event_images  }) => {
    let cover_image = event_images.find(obj => {
        if(obj.event_id === event.id && obj.image_index === 1) {
            return obj.event_id === event.id && obj.image_index === 1
        }
        else {
            return ''
        }
    });

    var image = '';
    if(cover_image) {
        if (cover_image.image === '') {
            image = noImage;
        }
        else {
            if(cover_image.image instanceof Blob) {
                image = URL.createObjectURL(cover_image.image);
            }
            else {
                image = noImage;
            }
        }
    }
    else {
        image = noImage;
    }
    return (
        event.archived === false ?
        <div className='truncate tc bg-moon-gray dib br3 pa3 ma2 bw2 dim shadow-5 mw5' onClick={async () => {
                await loadViewingEvent(event.id);
                await onRouteChange('viewEvent');
            }}>
            <div className='bg-light-green'>
                <textarea value={'Open'} style={{resize: 'none', color: 'black', height: '3em'}} className='title-area tc pa2 db fw6 f5 center w-100' disabled={true}></textarea> 
            </div>
            <React.Fragment>    
                <img alt={'Loading'} src={image} width='300px' height='300px'/>
                <textarea value={event.event_title} style={{resize: 'none', color: 'black', height: '3em'}} className='title-area tc pa2 db fw6 f5 center w-100' disabled={true}></textarea>
                <p>{event.event_type}</p>
            </React.Fragment>
        </div>
        :
        <div className='truncate tc bg-moon-gray  dib br3 pa3 ma2 bw2 dim shadow-5 mw5' onClick={async () => {
                await loadViewingEvent(event.id);
                await onRouteChange('viewEvent');
            }}>
            <div className='bg-light-red'>
                <textarea value={'Closed'} style={{resize: 'none', color: 'black', height: '3em'}} className='title-area tc pa2 db fw6 f5 center w-100' disabled={true}></textarea> 
            </div>
            <React.Fragment>   
                <img alt={'Loading'} src={image} width='300px' height='300px'/>
                <textarea value={event.event_title} style={{resize: 'none', color: 'black', height: '3em'}} className='title-area tc pa2 db fw6 f5 center w-100' disabled={true}></textarea>
                <p>{event.event_type}</p>
            </React.Fragment>
        </div>
    );
}

export default Event