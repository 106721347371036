import React from 'react';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.onRouteChange  = this.props.onRouteChange;
        this.state = {
            email: '',
            error: ''
        }
    }

    onEmailChange = async (event) => {
        await this.setState({email: event.target.value});
    }

    onSubmitSignIn = async () => {
        let status = null;

        await this.setState({error: ''});

        await fetch('https://regrantdev.com:443/forgotpassword', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: this.state.email,
            })
        })
        .then(response => {
            status = response.status
            return response.json()
        })
        .then(async user => {
            if (status !== 400) {
                await this.onRouteChange('forgotPasswordChangeEmailSent');
            }
            else {
                await this.setState({error: user.error});
            }
        })
        .catch(async err => await this.setState({error: 'Cannot connect to server'}));
    }

    render() {
        return (
            <article className="br3 ba b--black-10 mv4 mw6 shadow-5 center bg-light-gray">
                <main className="pa4 black-80">
                    <div className="measure">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <label className="f1 fw6 ph0 mh0">Forgot Password</label>
                            <div className="mt3">
                                <label className="db fw6 lh-copy f6" htmlFor="email-address">Email</label>
                                <input 
                                    className="pa2 input-reset ba w-100 tc" 
                                    type="email" 
                                    name="email-address"  
                                    id="email-address"
                                    maxLength="255"
                                    onChange={this.onEmailChange}
                                    required
                                />
                                <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error}</label>
                            </div>
                        </fieldset>
                        <div className="">
                            <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
                                type="submit" 
                                value="Submit" 
                                onClick={this.onSubmitSignIn}
                            />
                        </div>
                        <div className="lh-copy mt3">
                            <p onClick={async () => await this.onRouteChange('signin')} className="f6 link dim black db pointer">Back to sign in</p>
                        </div>
                    </div>
                </main>
            </article>
        );
    }
}

export default ForgotPassword;