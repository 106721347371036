import React from 'react';

class Signin extends React.Component {
    constructor(props) {
        super(props);
        this.onRouteChange  = this.props.onRouteChange;
        this.loadUser = this.props.loadUser;
        this.setSignIn = this.props.setSignIn;
        this.setEmail = this.props.setEmail;
        this.state = {
            signInEmail: '',
            signInPassword: '',
            error: ''
        }
    }

    onEmailChange = async (event) => {
        await this.setState({signInEmail: event.target.value});
    }

    onPasswordChange = async (event) => {
        await this.setState({signInPassword: event.target.value});
    }

    onSubmitSignIn = async () => {
        let status = null;

        await this.setState({error: ''});

        await fetch('https://regrantdev.com:443/signin', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: this.state.signInEmail,
                password: this.state.signInPassword
            })
        })
        .then(response => {
            status = response.status
            return response.json()
        })
        .then(async user => {
            if(status !== 400 && user.change_password === false && user.account_validated === true) {
                await this.loadUser(user);
                await this.setSignIn(true);
                await this.onRouteChange('home', true);
            }
            else if(status !== 400 && user.change_password === true) {
                await this.setEmail(this.state.signInEmail);
                await this.onRouteChange('passwordReset');
            }
            else if(status !== 400 && user.account_validated === false) {
                await this.setEmail(this.state.signInEmail);
                await this.onRouteChange('accountValidation');
            }
            else {
                await this.setState({error: user});
            }
        })
        .catch(async err => await this.setState({error: 'Cannot connect to server'}));
    }

    render() {
        return (
            <React.Fragment>
                <React.Fragment>
                    <div className='white f1 pt1 fw6'>
                        {'PlanitWeb'}
                    </div>
                    <div className='white f3 fw5 pb3'>
                        {'Event Planning Made Easy'}
                    </div>
                </React.Fragment>
                <article className="br3 ba b--black-10 mv4 mw6 shadow-5 center bg-light-gray">
                    <main className="pa4 black-80">
                        <div className="measure">
                            <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                                <label className="f1 fw6 ph0 mh0 tc">Sign In</label>
                                <div className="mt3">
                                    <label className="db fw6 lh-copy f6" htmlFor="email-address">Email</label>
                                    <input 
                                        className="pa2 input-reset ba w-100 tc" 
                                        type="email" 
                                        name="email-address"  
                                        id="email-address"
                                        maxLength="255"
                                        onChange={this.onEmailChange}
                                        required
                                    />
                                </div>
                                <div className="mv3">
                                    <label className="db fw6 lh-copy f6" htmlFor="password">Password</label>
                                    <input 
                                        className="b pa2 input-reset ba w-100 tc" 
                                        type="password" 
                                        name="password"  
                                        id="password"
                                        maxLength="255"
                                        onChange={this.onPasswordChange}
                                        required
                                    />
                                </div>
                            </fieldset>
                            <React.Fragment>
                                <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
                                    type="submit" 
                                    value="Sign in" 
                                    onClick={this.onSubmitSignIn}
                                />
                            </React.Fragment>
                            <div className='pt3'>
                                <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error}</label>
                            </div>
                            <div className="lh-copy mt3">
                                <p onClick={async () => await this.onRouteChange('register')} className="f6 link dim black db pointer">Register</p>
                                <p onClick={async () => await this.onRouteChange('forgotPassword')} className="f6 link dim black db pointer">Forgot your password?</p> 
                            </div>
                        </div>
                    </main>
                </article>
            </React.Fragment>
        );
    }
}

export default Signin;