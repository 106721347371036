import React from 'react';
import * as BootStrapIcons from 'react-icons/bi';

const BackToTopBtn = ({ backToTopBtnClicked }) => {

  return (
    <React.Fragment>
        <div className='grow pointer f5' style={{ color: '#fff' }}>
            <span className='f2'>
                <BootStrapIcons.BiArrowToTop onClick={async () => backToTopBtnClicked()}/>
            </span >
        </div>
    </React.Fragment>
  );
}

export default BackToTopBtn;