import React from 'react';
import ImageTools from '../../Tools/ImageTools.js'
import * as AIIcons from 'react-icons/ai';

class CreateEvent extends React.Component {
    constructor(props) {
        super(props);
        this.onRouteChange  = this.props.onRouteChange;
        this.loadViewingEvent = this.props.loadViewingEvent;
        this.setSearchField = this.props.setSearchField;
        this.id = this.props.id;
        this.name = this.props.name;
        this.user_type = this.props.user_type;

        this.state = {
            title: '',
            description: '',
            eventType: 'In Person',
            image1: '',
            image2: '',
            image3: '',
            image1Name: '',
            image2Name: '',
            image3Name: '',
            image1Display: '',
            image2Display: '',
            image3Display: '',
            image1CancelBtn: false,
            image2CancelBtn: false,
            image3CancelBtn: false,
            image1Resized: false,
            image2Resized: false,
            image3Resized: false,
            submitBtn: true,
            checkBtn: false,
            error: {
                title: '',
                image1: '',
                image2: '',
                image3: '',
                description: '',
                general_error: ''
            }
        }
    }

    onCheckBtn = async () => {
        await this.setState({checkBtn: !this.state.checkBtn});
    }

    onTitleChange = async (event) => {
        await this.setState({title: event.target.value});
    }

    onDescriptionChange = async (event) => {
        await this.setState({description: event.target.value});
    }

    onEventTypeChange = async (event) => {
        await this.setState({eventType: event.target.value});
    }

    onImage1Change = async (event) => {
        await this.setState({
            image1: event.target.files[0],
            image1Name: event.target.files[0].name,
        }); 
        await this.imageResize(this.state.image1, 1);
    }

    onImage2Change = async (event) => {
        await this.setState({
            image2: event.target.files[0],
            image2Name: event.target.files[0].name,
        });
        await this.imageResize(this.state.image2, 2);
    }

    onImage3Change = async (event) => {
        await this.setState({
            image3: event.target.files[0],
            image3Name: event.target.files[0].name,
        });
        await this.imageResize(this.state.image3, 3);
    }

    onImage1CancelBtn = async (event) => {
        await this.setState({
            image1: '',
            image1Name: '',
            image1Display: '',
            image1CancelBtn: false,
            image1Resized: false
        }); 
    }

    onImage2CancelBtn = async (event) => {
        await this.setState({
            image2: '',
            image2Name: '',
            image2Display: '',
            image2CancelBtn: false,
            image2Resized: false
        }); 
    }

    onImage3CancelBtn = async (event) => {
        await this.setState({
            image3: '',
            image3Name: '',
            image3Display: '',
            image3CancelBtn: false,
            image3Resized: false
        }); 
    }

    imageResize = async (img, imageID) => {
        try {
            if(imageID === 1) {
                ImageTools.resize(img, { width: 300 }, async blob => {
                    await this.setState({
                        image1: blob,
                        image1Display: URL.createObjectURL(blob),
                        image1Resized: true,
                        image1CancelBtn: true
                    });
                })
            }
            else if(imageID === 2) {
                ImageTools.resize(img, { width: 300 }, async blob => {
                    await this.setState({
                        image2: blob,
                        image2Display: URL.createObjectURL(blob),
                        image2Resized: true,
                        image2CancelBtn: true
                    });
                })
            }
            else if(imageID === 3) {
                ImageTools.resize(img, { width: 300 }, async blob => {
                    await this.setState({
                        image3: blob,
                        image3Display: URL.createObjectURL(blob),
                        image3Resized: true,
                        image3CancelBtn: true
                    });
                })
            }
        }
        catch (err){
            await this.setState({error: {general_error: err.toString()}});
        }
    }

    onSubmitCreateEvent = async () => {
        if (this.state.submitBtn === false ) {
            return;
        }

        await this.setState({submitBtn: false});
        let status = null;

        await this.setState({error: {
            title: '',
            image1: '',
            image2: '',
            image3: '',
            description: '',
            general_error: ''
          }});

         let sendData = JSON.stringify({
            user_id: this.id,
            name: this.name,
            title: this.state.title.trim(),
            event_type: this.state.eventType, 
            description: this.state.description.trim(),
            is_private: this.state.checkBtn,
            image1Name: this.state.image1Name,
            image1Resized: this.state.image1Resized,
            image2Name: this.state.image2Name,
            image2Resized: this.state.image2Resized,
            image3Name: this.state.image3Name,
            image3Resized: this.state.image3Resized,
        });
        
        var fd = new FormData();
        await fd.append('image1', this.state.image1);
        await fd.append('image2', this.state.image2);
        await fd.append('image3', this.state.image3);
        await fd.append('data', sendData);

        await fetch('https://regrantdev.com:443/createevent', {
            method: 'post',
            body: fd
            })
        .then(response => {
            status = response.status
            return response.json()
        })
        .then(async event => {
            if (status !== 400) {
                await this.loadViewingEvent(event[0].id);
                await this.setSearchField(event, '', '');
                await this.onRouteChange('viewEvent');
            }
            else {
                await this.setState({error: {
                    title: event.title,
                    image1: event.image1,
                    image2: event.image2,
                    image3: event.image3,
                    description: event.description,
                    general_error: event.general_error
                }});
                await this.setState({submitBtn: true});
            }
        })
        .catch(async err => {
            await this.setState({error: {
                title: '',
                image1: '',
                image2: '',
                image3: '',
                description: '',
                general_error: 'Cannot connect to server'
            }})
            await this.setState({submitBtn: true});
        });
    }

    render() {
        let width_value = 80;
        if (window.innerWidth <= 950) {
            width_value = 100;
        }

        return (
            <div className='center pb1'>
                <article className={`br3 ba b--black-10 shadow-5 bg-light-gray w-${width_value}`}>
                    <main className="pa4 black-80">
                        <fieldset id="create_event" className="ba b--transparent pa2">
                            <React.Fragment>
                                <label className="f1 fw6 ph0 mh0">Create Event</label>
                            </React.Fragment>
                            <div className="mt3">
                                <label className="db fw6 lh-copy f6" htmlFor="title">Title</label>
                                <input 
                                    className="pa2 input-reset ba w-100 tc" 
                                    type="text" 
                                    name="title"
                                    title="100 character limit" 
                                    minLength="1"
                                    maxLength="100"  
                                    id="title"
                                    onChange={this.onTitleChange}
                                    required
                                />
                                <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.title}</label>
                            </div>
                            <div className="mt3">
                                <label htmlFor="comment" className="f6 b db mb2">Description</label>
                                <textarea 
                                id="comment" 
                                name="comment" 
                                title="1024 character limit" 
                                className="db border-box hover-black w-100 ba b--black-20 pa2 br2 mb2"
                                style={{resize: "none", height: '15em'}}
                                aria-describedby="comment-desc"
                                onChange={this.onDescriptionChange}
                                maxLength="1024"
                                required
                                > 
                                </textarea>
                                <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.description}</label>
                            </div>
                        </fieldset>
                        <div className='space'>
                            <React.Fragment>
                                <select onChange={this.onEventTypeChange} value={this.state.eventType} id="types" className="db h2 ph3 f4 ba">
                                    <option label="In Person" value="In Person">Space Type
                                    </option>
                                    <option label="Online" value="Online">Space Type
                                    </option>
                                </select>
                            </React.Fragment>
                            <div className='end'>
                                <div className='ma3 mb2'>
                                    <input type="checkbox" onChange={this.onCheckBtn} defaultChecked={this.state.checkBtn}/>
                                </div>
                                <React.Fragment>
                                    <p>Private</p>
                                </React.Fragment>
                            </div>
                        </div>
                        <div className='pa2 mt3 center'>
                            <div className='column'>
                                <label className='db fw6 lh-copy f4'>Cover Image</label>
                                <div className='pa3 ba bw3'>
                                    <img alt='' src={this.state.image1Display} width='300px' height='300'/>
                                </div>
                                <div className='mt1 grow pointer f5'>
                                    <AIIcons.AiOutlineClose style={{visibility: this.state.image1CancelBtn ? 'visible' : 'hidden'}} onClick={this.onImage1CancelBtn}/>
                                </div>
                                <div className='mt3'>
                                    <input className='w-100' type="file" encType="multipart/form-data" accept="image/png,image/jpeg" onChange={this.onImage1Change} width='300px' height='auto'/>
                                </div>
                                <div className='pt3'>
                                    <label className='w-100 bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.image1}</label>
                                </div>
                            </div>
                        </div>
                        <div className='pa2 mt3 center'>
                            <div className='column'>
                                <label className='db fw6 lh-copy f4'>Image 2</label>
                                <div className='pa3 ba bw3'>
                                    <img alt='' src={this.state.image2Display} width='300px' height='300'/>
                                </div>
                                <div className='mt1 grow pointer f5'>
                                    <AIIcons.AiOutlineClose style={{visibility: this.state.image2CancelBtn ? 'visible' : 'hidden'}} onClick={this.onImage2CancelBtn}/>
                                </div>
                                <div className='mt3'>
                                    <input className='w-100' type="file" encType="multipart/form-data" accept="image/png,image/jpeg" onChange={this.onImage2Change} width='300px' height='auto'/>
                                </div>
                                <div className='pt3'>
                                    <label className='w-100 bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.image2}</label>
                                </div>
                            </div>
                        </div>
                        <div className='pa2 mt3 center'>
                            <div className='column'>
                                <label className='db fw6 lh-copy f4'>Image 3</label>
                                <div className='pa3 ba bw3'>
                                    <img alt='' src={this.state.image3Display} width='300px' height='300'/>
                                </div>
                                <div className='mt1 grow pointer f5'>
                                    <AIIcons.AiOutlineClose style={{visibility: this.state.image3CancelBtn ? 'visible' : 'hidden'}} onClick={this.onImage3CancelBtn}/>
                                </div>
                                <div className='mt3'>
                                    <input className='w-100' type="file" encType="multipart/form-data" accept="image/png,image/jpeg" onChange={this.onImage3Change} width='300px' height='auto'/>
                                </div>
                                <div className='pt3'>
                                    <label className='w-100 bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.image3}</label>
                                </div>
                            </div>
                        </div>
                        <div className='pt5 mt3'>
                            <input className="b ph4 pv2 input-reset ba b--black bg-washed-yellow pointer f5 dib" 
                                type="submit" 
                                value="Create Event" 
                                onClick={this.onSubmitCreateEvent}
                            />
                        </div>
                        <div className='pt3'>
                            <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.general_error}</label>
                        </div>
                    </main>
                </article>
            </div>
        );
    }
}

export default CreateEvent;