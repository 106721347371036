import React from 'react';
import * as BootStrapIcons from 'react-icons/bi';

const NavigationBackBtn = ({ navPreviousBtnState, backBtnClicked }) => {

  return (
    <React.Fragment>
        <div className='grow pointer f5' style={{ color: '#fff' }}>
            <span className='f2'><BootStrapIcons.BiArrowBack onClick={async () => {
                if(navPreviousBtnState === false) {
                    return;
                }
                else {
                    await backBtnClicked();
                }   
            }}/>
            </span >
        </div>
    </React.Fragment>
  );
}

export default NavigationBackBtn;