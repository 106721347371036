import React from 'react';
import UserAccount from '../Components/UserAccount/UserAccount.js';
import AccountValidation from '../Components/AccountValidation/AccountValidation.js';
import Signin from '../Components/Signin/Signin.js';
import Signout from '../Components/Signout/Signout.js';
import Navigation from '../Components/Navigation/Navigation.js';
import NavigationBtn from '../Components/Navigation/NavigationBtn.js';
import Register from '../Components/Register/Register.js';
import ForgotPassword from '../Components/ForgotPassword/ForgotPassword.js';
import ForgotPasswordEmailSent from '../Components/ForgotPasswordEmailSent/ForgotPasswordEmailSent.js';
import PasswordReset from '../Components/PasswordReset/PasswordReset.js';
import EventScroll from '../Components/EventScroll/EventScroll.js';
import EventList from '../Components/EventList/EventList.js';
import ViewEvent from '../Components/ViewEvent/ViewEvent.js';
import EventSearchBox from '../Components/EventSearchBox/EventSearchBox.js';
import ErrorBoundary from '../Components/ErrorBoundary/ErrorBoundary.js';
import PreviousBtn from '../Components/Navigation/NavigationBackBtn.js';
import BackToTopBtn from '../Components/Navigation/BackToTopBtn.js';
import CreateEvent from '../Components/CreateEvent/CreateEvent.js';
import UpdateEvent from '../Components/UpdateEvent/UpdateEvent.js';
import UpdateName from '../Components/UpdateName/UpdateName.js';
import SendNewEmailCode from '../Components/SendNewEmailCode/SendNewEmailCode.js';
import EmailValidation from '../Components/EmailValidation/EmailValidation.js';
import AttendingEvents from '../Components/AttendingEvents/AttendingEvents.js';
import './App.css';

const initialState = {
  route: 'home',
  previousRoutes: ['home'],
  isSignedIn: false,
  currentEmail: '',
  events: [],
  userAccountEvents: [],
  attendingEvents: [],
  currentEventList: '',
  loadMoreEvents: true,
  requestfromUrl: false,
  loadNewEventsID: '',
  viewingEventID: '',
  loadedEventsCount: '', 
  previousViewedEventsID: [],
  previousViewedEventsList: [],
  eventSearchText: '',
  privateSearchText: '',
  navBtnState: true,
  navPreviousBtnState: true,
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth,
  imageLists: [],
  updateEmail: '',
  passwordResetBackBtn: 'normal',
  user: {
    id: '',
    name: '',
    email: '',
    user_type: '',
    created_events: '',
    attending_events: [],
    login_count: 0,
    last_login: '',
    user_event_interests: ''
  }
}

class App extends React.Component {
  constructor() {
    super();
    let stateCheck = JSON.parse(localStorage.getItem('state')) || ''

    if (stateCheck === '') {
      this.state = initialState;
    }
    else {
      this.state = stateCheck;
    }
  }

  async componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("beforeunload", this.windowCloseEvent);
    await this.setState({loadNewEventsID: ''});

    if(window.innerWidth <= 950) {
      await this.setState({navBtnState: false});
    }

    let location = window.location.href;
    if (location.includes('/event/')) {
      let id_index = location.lastIndexOf("/");
      let id = location.substr(id_index+1);
      id = id.split('e')[0]
      let privtekey_index = location.lastIndexOf('e');
      let privatekey = location.substr(privtekey_index+1);
      await this.setState({
        requestfromUrl: true,
        loadNewEventsID: id,
        privateSearchText: privatekey,
        route:'home'
      })

      await window.history.replaceState("object or string", "Title", location.substring(0, location.indexOf("event")));
    }

    await this.loadEvents();
  }

  async componentWillUnmount() {
    await window.removeEventListener("resize", this.handleResize);
  }

  handleResize = async (event) => {
    await this.setState({
      windowHeight:window.innerHeight,
      windowWidth: window.innerWidth
    });
  };

  windowCloseEvent = async (event) => {
    try {
      let customState = {
        route: this.state.route,
        previousRoutes: this.state.previousRoutes,
        isSignedIn: this.state.isSignedIn,
        currentEmail: this.state.currentEmail,
        events: this.state.events,
        userAccountEvents: this.state.userAccountEvents,
        attendingEvents: this.state.attendingEvents,
        currentEventList: this.state.currentEventList,
        loadMoreEvents: this.state.loadMoreEvents,
        requestfromUrl: this.state.requestfromUrl,
        loadNewEventsID: this.state.loadNewEventsID,
        viewingEventID: this.state.viewingEventID,
        loadedEventsCount: this.state.loadedEventsCount, 
        previousViewedEventsID: this.state.previousViewedEventsID,
        previousViewedEventsList: this.state.previousViewedEventsList,
        eventSearchText: this.state.eventSearchText,
        privateSearchText: this.state.privateSearchText,
        navBtnState: this.state.navBtnState,
        navPreviousBtnState: this.state.navPreviousBtnState,
        windowHeight: this.state.windowHeight,
        windowWidth: this.state.windowWidth,
        imageLists: [],
        updateEmail: this.state.updateEmail,
        passwordResetBackBtn: this.state.passwordResetBackBtn,
        user: {
          id: this.state.user.id,
          name: this.state.user.name,
          email: this.state.user.email,
          user_type: this.state.user.user_type,
          created_events: this.state.user.created_events,
          attending_events: this.state.user.attending_events,
          login_count: this.state.user.login_count,
          last_login: this.state.user.last_login,
          user_event_interests: this.state.user.user_event_interests
        }
      }
      localStorage.setItem('state', JSON.stringify(customState));
    }
    catch {
      console.log('Can\'t save to local storage');
    }
  }

  onRouteChange = async (route, isFromLoginState=false) => {
    if (!isFromLoginState) {
      if(this.state.previousRoutes[this.state.previousRoutes.length-1] !== this.state.route) {
        await this.setState({
          previousRoutes: this.state.previousRoutes.concat(this.state.route),
          navPreviousBtnState: true
        });
      }
    }
    
    await this.setState({route: route});

    if(route === 'home') {
      await this.setState({imageLists: []});
      await this.loadImages();
    }
  }

  backBtnClicked = async () => {
    if (this.state.previousRoutes.length !== 0){
      if (this.state.previousRoutes[this.state.previousRoutes.length-1] === 'viewEvent') {
        await this.setState({viewingEventID: this.state.previousViewedEventsID.pop()});
        await this.setState({currentEventList: this.state.previousViewedEventsList.pop()});
      }
      await this.setState({route: this.state.previousRoutes.pop()});
    }
    else {
        await this.setState({navPreviousBtnState: false});
    }
  }

  backToTopBtnClicked = async () => {
    try {
      document.getElementById('scroll-home').scrollTo({top: 0, behavior: 'smooth'});
    }
    catch {
      document.getElementById('scroll-content').scrollTo({top: 0, behavior: 'smooth'});
      try {
        document.getElementById('scroll-inner-content').scrollTo({top: 0, behavior: 'smooth'});
      }
      catch {

      }
    }
    finally {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  loadEvents = async (append=0) => {
    if(this.state.loadMoreEvents === false) {
      return;
    }

    let status = null;

    await fetch('https://regrantdev.com:443/events', {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          search: this.state.eventSearchText,
          loadEvents: this.state.loadMoreEvents,
          loadNewEventsID: this.state.loadNewEventsID,
          requestfromSearch: false,
          requestfromUrl: this.state.requestfromUrl,
          privateKeySearch: this.state.privateSearchText
      })
    })
    .then(response => {
      status = response.status
      return response.json();
    })
    .then(async eventsData => {
      if(eventsData === 'empty') {
        await this.setState({
          loadMoreEvents: false,
          events: []
        });
        return;
      }

      if(status !== 400){
        if(append === 1) {
          if(this.state.requestfromUrl === true) {

            await this.setState({
              requestfromUrl: false,
              loadedEventsCount: eventsData.length,
              events: eventsData
            });
          }
          else {
            await this.setState({
              loadedEventsCount: eventsData.length,
              events: this.state.events.concat(eventsData)
            });
          }
          await this.loadImages();
        }
        else {
          if(this.state.requestfromUrl === true) {
            await this.setState({
              requestfromUrl: false,
              loadedEventsCount: eventsData.length,
              events: eventsData
            });
          }
          else {
            await this.setState({
              loadedEventsCount: eventsData.length,
              events: eventsData,
            });
          }
          await this.loadImages();
        }
      }
      else {
        await this.setState({
          loadMoreEvents: false,
        });
      }
    })
    .catch(async err => {console.log(err)});
  }

  loadImages = async () => {
    for(let image_index = 1; image_index <= 3; image_index++){
      for(let i = 0, loadindex = this.state.events.length - this.state.loadedEventsCount; i < this.state.loadedEventsCount; i++, loadindex++) {
        if(loadindex < 0 || loadindex === undefined) {
          return;
        }
        let status = null;
        var fd = new FormData();  
        let sendData = JSON.stringify({
            eventID: this.state.events[loadindex].id,
            image_index: image_index
        });
        fd.append('data', sendData);
        await fetch('https://regrantdev.com:443/eventimages', {
            method: 'post',
            body: fd
        })
        .then(response => {
            status = response.status
            return response.blob();
        })
        .then(async image => {
            if (status === 200) {
              let data = [{
                'event_id': this.state.events[loadindex].id,
                'image_index': image_index,
                'image': image
              }]
              await this.setState({imageLists: this.state.imageLists.concat(data)});
            }
            else {
              let data = [{
                'event_id': this.state.events[loadindex].id,
                'image_index': image_index,
                'image': ''
              }]
              await this.setState({imageLists: this.state.imageLists.concat(data)});
            }
        })
        .catch(async err => {
          if(loadindex >= 0 || loadindex === undefined) {
            let data = [{
              'event_id': this.state.events[loadindex].id,
              'image_index': image_index,
              'image': ''
            }]
            await this.setState({imageLists: this.state.imageLists.concat(data)});
          }
        })
      }
    }
  }

  loadUser = async (data) => {
    await this.setState({user: {
      id: data.id,
      name: data.name,
      email: data.email,
      user_type: data.user_type,
      created_events: data.created_events,
      attending_events: data.attending_events,
      login_count: data.login_count,
      last_login: data.last_login,
      user_event_interests: data.user_event_interests
    }});
  }

  loadViewingEvent = async (eventID) => {
    if(this.state.route === 'userAccount') {
      await this.setState({currentEventList: 'userAccount'});
    }
    else if(this.state.route === 'attendingEvents') {
      await this.setState({currentEventList: 'attendingEvents'});
    }
    else {
      await this.setState({currentEventList: 'home'});
    }

    await this.setState({
      viewingEventID: eventID,
      previousViewedEventsID: this.state.previousViewedEventsID.concat(eventID),
      previousViewedEventsList: this.state.previousViewedEventsList.concat(this.state.currentEventList)
    });
  }

  updateLoadedEventsNumber = async () => {
    if(this.state.events[this.state.events.length-1].id >= 1) {
      let lastId = this.state.events[this.state.events.length-1].id - 1
      if (lastId <= 0) {
        return;
      }
      else {
        await this.setState({
          loadNewEventsID: lastId,
        });
        await this.loadEvents(1);
      }
    }
  }

  updateUserAttendingEvent = async (event_array) => {
    await this.setState({user: {
      id: this.state.user.id,
      name: this.state.user.name,
      email: this.state.user.email,
      user_type: this.state.user.user_type,
      created_events: this.state.user.created_events,
      attending_events: event_array,
      login_count: this.state.user.login_count,
      last_login: this.state.user.last_login,
      user_event_interests: this.state.user.user_event_interests
    }});

  }

  updateUserName = async (name) => {
    await this.setState({user: {
      id: this.state.user.id,
      name: name,
      email: this.state.user.email,
      user_type: this.state.user.user_type,
      created_events: this.state.user.created_events,
      attending_events: this.state.user.attending_events,
      login_count: this.state.user.login_count,
      last_login: this.state.user.last_login,
      user_event_interests: this.state.user.user_event_interests
    }});
  }

  updateUserEmail = async (email) => {
    await this.setState({user: {
      id: this.state.user.id,
      name: this.state.user.name,
      email: email,
      user_type: this.state.user.user_type,
      created_events: this.state.user.created_events,
      attending_events: this.state.user.attending_events,
      login_count: this.state.user.login_count,
      last_login: this.state.user.last_login,
      user_event_interests: this.state.user.user_event_interests
    }});
  }

  setSearchField = async (newEvents, eventSearchText, privateSearchText) => {
    await this.setState({
        eventSearchText: eventSearchText,
        privateSearchText: privateSearchText,
        loadedEventsCount: newEvents.length,
        loadMoreEvents: true,
        events: newEvents
      });
    await this.loadImages();
  }

  setSignIn = async (value) => {
    if (value === true) {
      await this.setState({
        isSignedIn: true,
        previousRoutes: ['home'],
        previousViewedEventsID: [],
        previousViewedEventsList: []
      });
    }
    else {
      await this.setState(initialState);
      try {
        await localStorage.setItem('state', JSON.stringify(this.state));
      }
      catch {
        console.log('Can\'t save to local storage');
      }
    }
  }

  setEmail = async (value) => {
    await this.setState({currentEmail: value});
  }

  setUpdateEmail = async (value) => {
    await this.setState({updateEmail: value});
  }

  setNavBtnState = async (value) => {
    await this.setState({navBtnState: value});
  }

  setUserAccountEvents = async (events) => {
    await this.setState({userAccountEvents: events});
  }

  setAttendingEvents = async (events) => {
    await this.setState({attendingEvents: events});
  }

  setPasswordResetBackBtn = async (value) => {
    await this.setState({passwordResetBackBtn: value});
  }

  getEmail = async () => {
    if(this.state.passwordResetBackBtn === 'normal') {
      return this.state.currentEmail;
    }
    else {
      return this.state.user.email;
    }
  }

  getEvent = () => {
    var event = '';
    if(this.state.currentEventList === 'userAccount') {
      event = this.state.userAccountEvents.find(obj => {
        return obj.id === this.state.viewingEventID
      });
      
    }
    else if(this.state.currentEventList === 'attendingEvents') {
      event = this.state.attendingEvents.find(obj => {
        return obj.id === this.state.viewingEventID
      });
      
    }
    else {
      event = this.state.events.find(obj => {
        return obj.id === this.state.viewingEventID
      });
    }
    return event;
  }

  renderSwitch = (route, display) => {
    switch(route) {
      case 'home':
        return (
          <React.Fragment>
            {
            window.innerWidth > 950 ?
            <div>
              <div className='start bg-color-top-menu' style={{width: window.innerWidth}}>
                <NavigationBtn navBtnState={this.state.navBtnState} setNavBtnState={this.setNavBtnState}/>
                <div className='center'>
                  {
                    display === true ?
                      <EventSearchBox setSearchField={this.setSearchField}/>
                    :
                    <div></div>
                  }
                </div>
              </div>
              <div className='end' style={{width: window.innerWidth}}>
                <div>
                  <Navigation setNavBtnState={this.setNavBtnState} navBtnState={this.state.navBtnState} setSignIn={this.setSignIn} isSignedIn={this.state.isSignedIn} onRouteChange={this.onRouteChange}/>
                </div>
                <React.Fragment>
                    <div id='scroll-content' style={{flexGrow: 1, border: '3px solid black'}}>
                      <div className='space ph4'>
                          <PreviousBtn navPreviousBtnState={this.state.navPreviousBtnState} backBtnClicked={this.backBtnClicked}/>
                          <div className='end'>
                            <BackToTopBtn backToTopBtnClicked={this.backToTopBtnClicked}/>
                          </div>
                      </div>
                      <EventScroll updateLoadedEventsNumber={this.updateLoadedEventsNumber} scrollMenu={'home'}>
                        <EventList event_images={this.state.imageLists} events={this.state.events} onRouteChange={this.onRouteChange} loadViewingEvent={this.loadViewingEvent}/>
                      </EventScroll>
                    </div>
                </React.Fragment>
              </div>
            </div>
            :
            <div>
              <div className='start bg-color-top-menu' style={{width: window.innerWidth}}>
                <NavigationBtn navBtnState={this.state.navBtnState} setNavBtnState={this.setNavBtnState}/>
              </div>
              <div className='end' style={{width: window.innerWidth}}>
                <div>
                  <Navigation setNavBtnState={this.setNavBtnState} navBtnState={this.state.navBtnState} setSignIn={this.setSignIn} isSignedIn={this.state.isSignedIn} onRouteChange={this.onRouteChange}/>
                </div>
                <React.Fragment>
                    <div id='scroll-content' style={{flexGrow: 1, border: '3px solid black'}}>
                      <div className='space ph4'>
                          <PreviousBtn navPreviousBtnState={this.state.navPreviousBtnState} backBtnClicked={this.backBtnClicked}/>
                          <div className='end'>
                            <BackToTopBtn backToTopBtnClicked={this.backToTopBtnClicked}/>
                          </div>
                      </div>
                      <EventScroll updateLoadedEventsNumber={this.updateLoadedEventsNumber} scrollMenu={'home'}>
                      <div className='center'>
                        {
                          display === true ?
                            <EventSearchBox setSearchField={this.setSearchField}/>
                          :
                          <div></div>
                        }
                      </div>
                        <EventList event_images={this.state.imageLists} events={this.state.events} onRouteChange={this.onRouteChange} loadViewingEvent={this.loadViewingEvent}/>
                      </EventScroll>
                    </div>
                </React.Fragment>
              </div>
            </div>
            }
          </React.Fragment>
        );
      case 'signin':
        return <Signin setEmail={this.setEmail} setSignIn={this.setSignIn} loadUser={this.loadUser} onRouteChange={this.onRouteChange}/>
      case 'register':
        return <Register setEmail={this.setEmail} onRouteChange={this.onRouteChange}/>
      case 'forgotPassword':
        return <ForgotPassword onRouteChange={this.onRouteChange}/>
      case 'forgotPasswordChangeEmailSent':
        return <ForgotPasswordEmailSent onRouteChange={this.onRouteChange}/>
      case 'createEvent':
        return  <CreateEvent 
          id={this.state.user.id}
          name={this.state.user.name}
          user_type={this.state.user.user_type}
          onRouteChange={this.onRouteChange}
          loadViewingEvent={this.loadViewingEvent}
          setSearchField={this.setSearchField}
        />
      case 'viewEvent':
        let event = this.getEvent();
        let event_ID = event.id;
        let event_UserID = parseInt(event.user_id);
        let event_Status = event.archived;
        let initialEvent = '';
        
        if (this.state.user.attending_events !== undefined) {
          initialEvent = this.state.user.attending_events.includes(event_ID) ? true : false;
        }
        else {
          initialEvent = false;
        }

        return <ViewEvent 
          backBtn={this.backBtnClicked} 
          userID={this.state.user.id}
          event_UserID={event_UserID}
          event_ID={event_ID}
          archivedStatus={event_Status}
          onRouteChange={this.onRouteChange} 
          eventData={this.getEvent} 
          currentView={this.state.currentEventList}
          initialEventStatus={initialEvent}
          attendingEvents={this.state.user.attending_events}
          updateUserAttendingEvent={this.updateUserAttendingEvent}
          loadViewingEvent={this.loadViewingEvent}
          setSearchField={this.setSearchField}
        />
      case 'passwordReset':
        return <PasswordReset 
          getEmail={this.getEmail} 
          setEmail={this.setEmail} 
          onRouteChange={this.onRouteChange}
          setPasswordResetBackBtn={this.setPasswordResetBackBtn}
          passwordResetBackBtn={this.state.passwordResetBackBtn}
        />
      case 'attendingEvents':
        return <AttendingEvents 
          id={this.state.user.id}
          user_type={this.state.user.user_type}
          name={this.state.user.name}
          email={this.state.user.email}
          onRouteChange={this.onRouteChange}
          loadViewingEvent={this.loadViewingEvent}
          setAttendingEvents={this.setAttendingEvents}
        />
      case 'signout':
        return <Signout onRouteChange={this.onRouteChange}/>
      case 'userAccount':
        return <UserAccount 
          id={this.state.user.id}
          user_type={this.state.user.user_type}
          name={this.state.user.name}
          email={this.state.user.email}
          onRouteChange={this.onRouteChange}
          loadViewingEvent={this.loadViewingEvent}
          setUserAccountEvents={this.setUserAccountEvents}
          setPasswordResetBackBtn={this.setPasswordResetBackBtn}
        />
        case 'accountValidation':
          return <AccountValidation setSignIn={this.setSignIn} loadUser={this.loadUser} getEmail={this.getEmail} setEmail={this.setEmail} onRouteChange={this.onRouteChange}/>
        case 'updateEvent':
          return <UpdateEvent 
            id={this.state.user.id}
            name={this.state.user.name}
            user_type={this.state.user.user_type}
            onRouteChange={this.onRouteChange}
            loadViewingEvent={this.loadViewingEvent}
            setSearchField={this.setSearchField}
            eventData={this.getEvent}
          />  
        case 'updateName':
          return <UpdateName 
            onRouteChange={this.onRouteChange}
            updateUserName={this.updateUserName}
            userID={this.state.user.id}
          />  
        case 'sendNewEmailCode':
          return <SendNewEmailCode currentEmail={this.state.user.email} setUpdateEmail={this.setUpdateEmail} onRouteChange={this.onRouteChange}/>
        case 'emailValidation':
          return <EmailValidation currentEmail={this.state.user.email} setUpdateEmail={this.setUpdateEmail} updateUserEmail={this.updateUserEmail} updateEmail={this.state.updateEmail} onRouteChange={this.onRouteChange}/>
      default:
        return (<React.Fragment><p className='bg-light-gray'>404 Not Found</p></React.Fragment>);
    }
  }
 
  render() {
    let display = true;

    if (this.state.navBtnState === true && window.innerWidth <= 950) {
      display = false;
    }

    try {
      return (
        <div className="App">
          <ErrorBoundary>
            {
              this.state.route !== 'home' ? 
                <React.Fragment>
                  <div className='start bg-color-top-menu' style={{width: window.innerWidth}}>
                    <NavigationBtn navBtnState={this.state.navBtnState} setNavBtnState={this.setNavBtnState}/>
                  </div>
                  <div className='start' style={{width: window.innerWidth}}>
                    <div>
                      <Navigation setNavBtnState={this.setNavBtnState} navBtnState={this.state.navBtnState} setSignIn={this.setSignIn} isSignedIn={this.state.isSignedIn} onRouteChange={this.onRouteChange}/>
                    </div>
                    <React.Fragment>
                        <div style={{flexGrow: 1, border: window.innerWidth <= 950 ? 'none' : '3px solid black'}}>
                          <div className='space ph4'>
                            <PreviousBtn navPreviousBtnState={this.state.navPreviousBtnState} backBtnClicked={this.backBtnClicked}/>
                            <div className='end'>
                              <BackToTopBtn backToTopBtnClicked={this.backToTopBtnClicked}/>
                            </div>
                          </div>
                          <div id='scroll-content' className='hideScroll' style={{overflowY: 'scroll', height: window.innerHeight-107}}>
                            {this.renderSwitch(this.state.route)}
                          </div>
                        </div>   
                    </React.Fragment>
                  </div>
                </React.Fragment>
              :
                <React.Fragment>
                  {this.renderSwitch(this.state.route, display)}
                </React.Fragment>
            }
          </ErrorBoundary>
        </div>
      );
    }
    catch (err) {
      localStorage.clear();
      return <h1>An Error Has Occured!</h1>;
    }
  }
}

export default App;
