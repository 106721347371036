import React from 'react';
import * as CGIcons from 'react-icons/cg';
import * as BIIcons from 'react-icons/bi';
import * as IMIcons from 'react-icons/im';

import './ViewEvent.css';

class ViewEvent extends React.Component {
    constructor(props) {
        super(props);
        this.onRouteChange = this.props.onRouteChange;
        this.loadViewingEvent = this.props.loadViewingEvent;
        this.setSearchField = this.props.setSearchField;
        this.eventData = this.props.eventData;
        this.userID = this.props.userID;
        this.event_UserID = this.props.event_UserID;
        this.event_ID = this.props.event_ID;
        this.archivedStatus = this.props.archivedStatus;
        this.currentView = this.props.currentView;
        this.initialEventStatus = this.props.initialEventStatus
        this.updateUserAttendingEvent = this.props.updateUserAttendingEvent;
        this.backBtn = this.props.backBtn;

        this.state = { 
            isButtonDisabled: false,
            eventActionBtnDisabled: false,
            addFlag: this.initialEventStatus,
            usersAttendingList: [],
            numOfUsersAttending: 0,
            showUsersAttending: false,
            searchText: '',
            image1: '',
            image2: '',
            image3: '',
            archived: this.archivedStatus,
            event: this.eventData()
        }
    }

    async componentDidMount () {
        this._isMounted = true;

        if (this.userID === this.event_UserID) {
            let status = null;

            await fetch('https://regrantdev.com:443/users_attending_event', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                event_id: this.event_ID,
                user_id: this.userID,
                })
            })
            .then(response => {
                status = response.status
                return response.json()
            })
            .then(async data => {
                if(status !== 400) {
                    if(this._isMounted) {
                        await this.setState({usersAttendingList: data});
                    }
                }
            })
            .catch(async err => {});
            if(this._isMounted) {
                await this.setState({
                    showUsersAttending: true,
                    numOfUsersAttending: this.state.usersAttendingList.length
                });
            }
        }
        else {
            let status = null;

            await fetch('https://regrantdev.com:443/users_attending_event_count', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                event_id: this.event_ID,
                })
            })
            .then(response => {
                status = response.status
                return response.json()
            })
            .then(async data => {
                if(status !== 400) {
                    if(this._isMounted) {
                        await this.setState({numOfUsersAttending: data});
                    }
                }
                else {
                    if(this._isMounted) {
                        await this.setState({numOfUsersAttending: 'error'});
                    }
                }
            })
            .catch(async err => {});
        }
        
        await this.getImages(1);
        await this.getImages(2);
        await this.getImages(3);
    }

    async componentWillUnmount () {
        this._isMounted = false;
    }

    getImages = async (index) => {
        let status = null;
        var fd = new FormData();  
        let sendData = JSON.stringify({
            eventID: this.event_ID,
            image_index: index
        });
        fd.append('data', sendData);
        await fetch('https://regrantdev.com:443/eventimages', {
            method: 'post',
            body: fd
        })
        .then(response => {
            status = response.status
            return response.blob();
        })
        .then(async image => {
            if(status === 200) {
                if (index === 1) {
                    if(image) {
                        if (image !== '') {
                            if(image instanceof Blob) {
                                if(this._isMounted) {
                                    await this.setState({image1: URL.createObjectURL(image)})
                                }
                            }
                        }
                    }
                }
                else if (index === 2) {
                    if(image) {
                        if (image !== '') {
                            if(image instanceof Blob) {
                                if(this._isMounted) {
                                    await this.setState({image2: URL.createObjectURL(image)})
                                }
                            }
                        }
                    }
                }
                else if (index === 3) {
                    if(image) {
                        if (image !== '') {
                            if(image instanceof Blob) {
                                if(this._isMounted) {
                                    await this.setState({image3: URL.createObjectURL(image)})
                                }
                            }
                        }
                    }
                }
            }
        })
        .catch(async err => {})
    }

    onSearchChange = async (event) => {
        if(this._isMounted) {
            await this.setState({searchText: event.target.value});
        }
    }

    onEventAction = async (event) => {
        let status = null;

        event.preventDefault();
        
        if(this._isMounted) {
            await this.setState({eventActionBtnDisabled: true});        
        }

        await fetch('https://regrantdev.com:443/updatearchived', {
            method: 'put',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                event_id: this.event_ID,
                archive_status: !this.state.archived //set opposite
            })
            })
            .then(response => {
                status = response.status
                return response.json()
            })
            .then(async data => {
                if(status !== 400) {
                    let eventArchiveChange = this.state.event;
                    eventArchiveChange.archived = !this.state.archived;
                    await this.loadViewingEvent(this.state.event.id);
                    await this.setSearchField([eventArchiveChange], '', '');
                    await window.location.reload();
                }
            })
            .catch(async err => {});
    }

    handleAttend = async () => {
        let status = null;
        
        if(this._isMounted) {
            await this.setState({ isButtonDisabled: true });        
        }
    
        setTimeout(async () => {
            if(this._isMounted) {
                await this.setState({ isButtonDisabled: false })
            }
        }, 2000);

        
        if (this.userID === '') {
            await this.onRouteChange('signin');
        }
        else {
            await fetch('https://regrantdev.com:443/attendevent', {
            method: 'put',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                event_id: this.event_ID,
                user_id: this.userID,
                add: this.state.addFlag
            })
            })
            .then(response => {
                status = response.status
                return response.json()
            })
            .then(async info => {
                if(status !== 400) {
                    await this.updateUserAttendingEvent(info);
                }
            })
            .catch(async err => {});
        }
    } 

    handleBackBtn = async () => {
        this.backBtn()
    }

    copyToClipboard = async (event) => {
        const text = document.createElement('textarea');
        text.value = `https://planitweb.us/event/${event.id}e${event.private_key}`;
        document.body.appendChild(text);
        text.select();
        document.execCommand('copy');
        document.body.removeChild(text);
      }

    render() {
        if(this._isMounted === false) {
            return
        }

        let width_value = 80;
        if (window.innerWidth <= 950) {
            width_value = 100;
        }

        let event = this.eventData();
        let filteredUsers = '';
        let event_title = '';
        let event_description = '';
        let event_type = '';

        let editButton = false;
        let closeEventBtn = false;
        let privateKey = false;
        let privateEvent = false;

        if(event !== undefined) {
            if (this.state.usersAttendingList.length && this.state.showUsersAttending === true) {
                filteredUsers = this.state.usersAttendingList.filter(user => {
                    return user[0].toLowerCase().includes(this.state.searchText.toLowerCase())
                });
            }
            event_title = event.event_title;
            event_description = event.event_description;
            event_type = event.event_type;

            editButton = false;
            if (this.userID === this.event_UserID) {
                editButton = true;
                closeEventBtn = true;
            }

            if (event.is_private === true) {
                privateEvent = true;
            }

            if (this.userID === this.event_UserID && event.is_private === true) {
                privateKey = true;
            }
        }

        return (
            <div className = 'center'>
            <article className={`w-${width_value} br3 ba b--black-10 shadow-5 bg-light-gray`}>
                <main className="pa4 black-80">
                    <div>
                        {
                            privateEvent === true ?
                            <div className='center'>
                                <div className='pointer dim f4 center'>
                                    <textarea value='Private Event' style={{resize: 'none', color: 'black', height: '1.4em'}} className='title-area tc db fw6 f2 center w-100' disabled={true}></textarea>
                                </div>
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                    <div className='space pb5'>
                        <div className='start'>
                            <div className='pointer dim f4'>
                                <h1><CGIcons.CgCopy onClick={async () => {await this.copyToClipboard(event)}}/></h1>
                            </div>
                            <div className='mt2'>
                                <h1>Copy</h1>
                            </div>
                        </div>
                        {
                            editButton === true ?
                            <div className='end'>
                                <div className='pointer dim f4 end'>
                                    <h1><BIIcons.BiEdit onClick={async () => await this.onRouteChange('updateEvent')}/></h1>
                                </div>
                                <div className='mt2'>
                                    <h1>Edit</h1>
                                </div>
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                    <div>
                        {
                            this.state.addFlag === true ?
                            <div className='center'>
                                <div className='pointer dim f4 end ph2'>
                                    <button disabled={this.state.isButtonDisabled} style={{border: 'none'}}>
                                        <h2><IMIcons.ImCheckboxChecked 
                                            onClick={async () => {
                                                if(this._isMounted) {
                                                    await this.setState({addFlag: false});
                                                    await this.handleAttend();
                                                }                                  
                                            }} 
                                        />
                                        </h2>
                                    </button>
                                </div>
                                <div className='mt1'>
                                    <h1>Attending</h1>
                                </div>
                            </div>
                            :
                            <div className='center'>
                                <div className='pointer dim f4 end ph2'>
                                    <button disabled={this.state.isButtonDisabled} style={{border: 'none'}}>
                                        <h2><IMIcons.ImCheckboxUnchecked disabled={this.state.isButtonDisabled} 
                                            onClick={async () => {
                                                if(this._isMounted) {
                                                    await this.setState({addFlag: true});
                                                    await this.handleAttend();
                                                } 
                                            }} 
                                        />
                                        </h2>
                                    </button>
                                </div>
                                <div className='mt1'>
                                    <h1>Not Attending</h1>
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                    {
                            privateKey === true ?
                            <div className='column'>
                                <div className='mt2 center'>
                                    <textarea value='Private Key:' style={{resize: 'none', color: 'black', height: '1.4em'}} className='title-area tc db fw6 f3 center w-100' disabled={true}></textarea>
                                </div>
                                <div className='pointer dim f4 center'>
                                    <textarea readOnly value={event.private_key} style={{resize: 'none', color: 'black', height: '3em'}} className='title-area center tc w-100'></textarea>
                                </div>
                            </div>
                            :
                            <div></div>
                    }
                    </div>
                    <fieldset id="sign_up" className="ba b--transparent">
                        <div className="mt3">
                            <div className='pb4'>
                                <textarea value={event_title} style={{resize: 'none', color: 'black', height: '3em'}} className='title-area tc pa2 db fw6 f2 center w-100' disabled={true}></textarea>
                            </div>
                            <div className='pb4'>
                                <label className='pa2 db fw6 f3 center w-100'>Description:</label>
                                <textarea value={event_description} style={{resize: 'none', color: 'black', height: '15em'}} className='pa2 db fw5 f4 center w-100' disabled={true}></textarea>
                            </div>
                            <label className='pa2 db fw6 f2'><b style={{color: 'red'}}>{event_type}</b></label>
                        </div>
                        {
                            this.state.image1 ?
                                <div className='pa2 mt3 center'>
                                    <div className='column'>
                                        <div className='pa3 ba bw3'>
                                            <img alt={''} src={this.state.image1} width='300px' height='300'/>
                                        </div>
                                    </div>
                                </div>
                            :
                            <div></div>
                        }
                        {
                            this.state.image2 ?
                                <div className='pa2 mt3 center'>
                                    <div className='column'>
                                        <div className='pa3 ba bw3'>
                                            <img alt={''} src={this.state.image2} width='300px' height='300'/>
                                        </div>
                                    </div>
                                </div>
                            :
                            <div></div>
                        }
                        {
                            this.state.image3 ?
                                <div className='pa2 mt3 center'>
                                    <div className='column'>
                                        <div className='pa3 ba bw3'>
                                            <img alt={''} src={this.state.image3} width='300px' height='300'/>
                                        </div>
                                    </div>
                                </div>
                            :
                            <div></div>
                        }
                    </fieldset>
                    {
                        closeEventBtn === true ?
                            <div className='pa3'>
                                <input style={{width: 'fit-content'}} className='b ph3 pv2 input-reset ba b--black bg-washed-yellow dim pointer f6 dib'
                                    type='submit'
                                    value={this.state.archived === false ? 'Close Event' : 'Reactivate'}
                                    onClick={this.onEventAction}
                                    disabled={this.state.eventActionBtnDisabled}
                                />
                            </div>
                        :
                        <div></div>
                    }
                    <React.Fragment>
                        <input className="b ph3 pv2 input-reset ba b--black bg-transparent dim pointer f6 dib" 
                            type="submit" 
                            value="Back"
                            onClick={this.handleBackBtn}
                        />
                    </React.Fragment>
                    <div>
                        {
                            this.state.showUsersAttending  === true ?
                            <div>
                                <div className='ph1 pt4'>
                                <p className='w-100'><b>{`Number of Attendees: ${this.state.numOfUsersAttending}`}</b></p>
                                    <input 
                                        size="50"
                                        className='w-30 ph3 pv2 ba b--green bg-lightest-blue tc'
                                        type='search' 
                                        placeholder='Filter Attendees'
                                        maxLength="255"
                                        onChange={this.onSearchChange}
                                    />
                                </div>
                                <React.Fragment>
                                    {
                                        filteredUsers.length ?
                                        filteredUsers.map((user, i) => {
                                            return (
                                                <li key={i} style={{listStyle: "none"}}>
                                                    <p><b>{filteredUsers[i]}</b></p>
                                                </li>
                                            );
                                        })
                                        :
                                        <label style={{color: 'red'}} className='pa2 db fw6 f4 center'>No Attendees</label>
                                    }
                                </React.Fragment>
                            </div>
                            :
                            <div>
                                <p className='w-100 pt4'><b>{`Number of Attendees: ${this.state.numOfUsersAttending}`}</b></p>
                            </div>
                        }
                    </div>
                </main>
            </article>
            </div>
        );
    }
}

export default ViewEvent;