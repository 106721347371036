import React from 'react';

import EventScroll from '../EventScroll/EventScroll.js';
import EventList from '../EventList/EventList.js';

import './UserAccount.css'

class UserAccount extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.onRouteChange = this.props.onRouteChange;
        this.loadViewingEvent = this.props.loadViewingEvent;
        this.setUserAccountEvents = this.props.setUserAccountEvents;
        this.setPasswordResetBackBtn = this.props.setPasswordResetBackBtn;
        this.id = this.props.id;
        this.name = this.props.name;
        this.email = this.props.email;
        this.user_type = this.props.user_type;

        this.state = {
            events: [],
            imageLists: [],
            searchText: '',
            isButtonDisabled: false,
            loadNewEventsID: '',
            loadedEventsCount: '', 
            loadMoreEvents: true,
            error: ''
        }

        if (this.name === '') {
            this.name = 'User'
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        await this.loadEvents();
    }

    async componentWillUnmount() {
      this._isMounted = false;
    }

    loadEvents = async (append=0) => {
      if(this.state.loadMoreEvents === false) {
        return;
      }
  
      let status = null;
  
      await fetch('https://regrantdev.com:443/userevents', {
          method: 'post',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            user_id: this.id,
            search: this.state.searchText,
            loadEvents: this.state.loadMoreEvents,
            loadNewEventsID: this.state.loadNewEventsID,
            requestfromSearch: false,
        })
      })
      .then(response => {
        status = response.status
        return response.json();
      })
      .then(async eventsData => {
        if(eventsData === 'empty') {
          if(this._isMounted) {
            await this.setState({
              loadMoreEvents: false,
            });
          }
          return;
        }
  
        if(status !== 400){
          if(append === 1) {
            if(this.state.requestfromUrl === true) {
              if(this._isMounted) {
                await this.setState({
                  loadedEventsCount: eventsData.length,
                  events: eventsData
                });
              }
              await this.setUserAccountEvents(eventsData);
            }
            else {
              if(this._isMounted) {
                await this.setState({
                  loadedEventsCount: eventsData.length,
                  events: this.state.events.concat(eventsData)
                });
              }
              await this.setUserAccountEvents(eventsData);
            }
            
            await this.loadImages();
          }
          else {
            if(this.state.requestfromUrl === true) {
              if(this._isMounted) {
                await this.setState({
                  loadedEventsCount: eventsData.length,
                  events: eventsData
                });
              }
              await this.setUserAccountEvents(eventsData);
            }
            else {
              if(this._isMounted) {
                await this.setState({
                  loadedEventsCount: eventsData.length,
                  events: eventsData,
                });
              }
              await this.setUserAccountEvents(eventsData);
            }
            if(this._isMounted) {
              await this.loadImages();
            }
          }
        }
        else {
          if(this._isMounted) {
            await this.setState({
              loadMoreEvents: false,
            });
          }
        }
      })
      .catch(async err => console.log('Cannot connect to Server'));
  }

  loadImages = async () => {
    for(let image_index = 1; image_index <= 3; image_index++){
      for(let i = 0, loadindex = this.state.events.length - this.state.loadedEventsCount; i < this.state.loadedEventsCount; i++, loadindex++) {
        if(loadindex < 0 || loadindex === undefined) {
          return;
        }
        let status = null;
        var fd = new FormData();  
        let sendData = JSON.stringify({
            eventID: this.state.events[loadindex].id,
            image_index: image_index
        });
        fd.append('data', sendData);
        await fetch('https://regrantdev.com:443/eventimages', {
            method: 'post',
            body: fd
        })
        .then(response => {
            status = response.status
            return response.blob();
        })
        .then(async image => {
            if (status === 200) {
              let data = [{
                'event_id': this.state.events[loadindex].id,
                'image_index': image_index,
                'image': image
              }]
              if(this._isMounted) {
                await this.setState({imageLists: this.state.imageLists.concat(data)});
              }
            }
            else {
              let data = [{
                'event_id': this.state.events[loadindex].id,
                'image_index': image_index,
                'image': ''
              }]
              if(this._isMounted) {
                await this.setState({imageLists: this.state.imageLists.concat(data)});
              }
            }
        })
        .catch(async err => {
          if(loadindex >= 0 || loadindex === undefined) {
            let data = [{
              'event_id': this.state.events[loadindex].id,
              'image_index': image_index,
              'image': ''
            }]
            if(this._isMounted) {
              await this.setState({imageLists: this.state.imageLists.concat(data)});
            }
          }
        })
      }
    }
  }

  onSubmitSearch = async () => {
    let status = null;
    
    if(this._isMounted) {
        await this.setState({error: ''});
    }

    await fetch('https://regrantdev.com:443/userevents', {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            user_id: this.id,
            search: this.state.searchText.toLowerCase(),
            loadEvents: true,
            loadNewEventsID: '',
            requestfromSearch: true,
        })
    })
    .then(response => {
        status = response.status
        return response.json()
    })
    .then(async eventData => {
        if(status !== 400 && eventData !== 'empty') {
            if(this._isMounted) {
                await this.setState({events: eventData});
                await this.setSearchField();
                await this.setUserAccountEvents(eventData);
            }
        }
        else {
            if(this._isMounted) {
                if (eventData !== 'empty') {
                    await this.setState({events: []});
                    await this.setUserAccountEvents([]);
                    await this.setState({error: eventData});
                }
                else {
                    await this.setState({events: []});
                    await this.setUserAccountEvents([]);
                    await this.setState({error: 'No events'});
                }
            }
        }
    })
    .catch(async err => {
        if(this._isMounted) {
            await this.setState({error: 'Cannot connect to server'})
        }
    });
    if(this._isMounted) {
      await this.loadImages();
    }
  }

  onSearchClicked = async (event) => {
    event.preventDefault();
    
    if(this._isMounted) {
        await this.setState({isButtonDisabled: true});        
    }

    setTimeout(async () => {
        if(this._isMounted) {
            await this.setState({ isButtonDisabled: false })
        }
    }, 1000);

    return await this.onSubmitSearch();
  }
  
  updateLoadedEventsNumber = async () => {
    if(this.state.events[this.state.events.length-1].id >= 1) {
      let lastId = this.state.events[this.state.events.length-1].id - 1
      if (lastId <= 0) {
        return;
      }
      else {
        if(this._isMounted) {
          await this.setState({
            loadNewEventsID: lastId,
          });
        }
        if(this._isMounted) {
          await this.loadEvents(1);
        }
      }
    }
  }

  setSearchField = async () => {
    if(this._isMounted) {
      await this.setState({
        loadedEventsCount: this.state.events.length,
        loadMoreEvents: true,
      });
    }
  }

  onSearchChange = async (event) => {
    if(this._isMounted) {
        await this.setState({searchText: event.target.value});
    }
  }

    render() {
      let width_value = 80;
      if (window.innerWidth <= 950) {
        width_value = 100;
      }
        return (
            <article className={`w-${width_value} br3 ba b--black-10 shadow-5 center bg-light-gray pb4`}>
                <main className=" pa4 black-80">
                    <div className="">
                        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                            <label className='pa1 db fw6 f2 center'>Name:</label>
                            <textarea value={this.name} style={{resize: 'none', color: 'black', height: '3em'}} className='title-area tc w-100 pa2 db f3 fw6' disabled={true}></textarea>
                            <div>
                                <label className='pa1 db fw6 f2 center'>Email:</label>
                                <textarea value={this.email} style={{resize: 'none', color: 'black', height: '3em'}} className='title-area tc w-100 pa2 db f3 fw6' disabled={true}></textarea>
                                {
                                  //<label className='w-100 pa2 db fw6 f2'>User Type: <b style={{color: 'red'}}>{this.user_type}</b></label>"</fieldset>
                                }
                                <div className='pt2'>
                                    <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
                                        type="submit" 
                                        value="Update Name" 
                                        onClick={async () => await this.onRouteChange('updateName')}
                                    />
                                </div>
                                <div className='pt2'>
                                    <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
                                        type="submit" 
                                        value="Update Email" 
                                        onClick={async () => await this.onRouteChange('sendNewEmailCode')}
                                    />
                                </div>
                                <div className='pt2'>
                                    <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
                                        type="submit" 
                                        value="Update Password" 
                                        onClick={async () => {
                                          await this.setPasswordResetBackBtn('userAccount');
                                          await this.onRouteChange('passwordReset');
                                        }}
                                    />
                                </div>
                                <div className='pt5'>
                                    <label className="w-100 f1 fw6 ph0 mh0"><b>My Events</b></label>                                
                                </div>
                                  <div className='center'>
                                    <div className='ph1 pt4'>
                                      <input 
                                          size="50"
                                          className='w-100 ph3 pv2 ba b--green bg-lightest-blue tc'
                                          type='search' 
                                          placeholder='Search Events'
                                          maxLength="255"
                                          onChange={this.onSearchChange}
                                          />
                                    </div>
                                    <div className='ph1 pt4'>
                                        <input className='w-100 b ph4 pv2 input-reset ba b--black bg-washed-yellow pointer f5 dib'
                                            type='submit'
                                            value='Search'
                                            onClick={this.onSearchClicked}
                                            disabled={this.state.isButtonDisabled}
                                        />
                                    </div>
                                  </div>
                                  <div className='pa2'>
                                    <label className='w-100 bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error}</label>
                                  </div>
                                  <div className='w-100'>
                                    <EventScroll updateLoadedEventsNumber={this.updateLoadedEventsNumber} scrollMenu={'userAccount'}>
                                      <EventList event_images={this.state.imageLists} events={this.state.events} onRouteChange={this.onRouteChange} loadViewingEvent={this.loadViewingEvent}/>
                                    </EventScroll>
                                  </div>
                              </div>
                        </fieldset>
                    </div>
                </main>
            </article>
        );
    }
}

export default UserAccount;