import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super();
        this.state = {
            hasError: false
        }
    }

    async componentDidCatch(error, info) {
        await this.setState({ hasError:true });
    }

    render() {
        if (this.state.hasError) {
            localStorage.clear();
            return <h1>An Error Has Occured!</h1>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary