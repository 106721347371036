import React from 'react';

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this.onRouteChange  = this.props.onRouteChange;
        this.setEmail = this.props.setEmail;
        this.getEmail = this.props.getEmail
        this.passwordResetBackBtn = this.props.passwordResetBackBtn;
        this.setPasswordResetBackBtn = this.props.setPasswordResetBackBtn;
        this.state = {
            password1: '',
            password2: '',
            error: {
                password1: '',
                password2: '',
                general_error: ''
            }
        }
    }

    password1_OnChange = async (event) => {
        await this.setState({password1: event.target.value});
    }

    password2_OnChange = async (event) => {
        await this.setState({password2: event.target.value});
    }

    onSubmitNewPassword = async () => {
        let status = null;
        
        await this.setState({error: {
            email: '',
            password: '',
            name: '',
            general_error: ''
          }});

        let email = await this.getEmail();

        await fetch('https://regrantdev.com:443/passwordreset', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: email,
                password1: this.state.password1,
                password2: this.state.password2
            })
        })
        .then(response => {
            status = response.status
            return response.json()
        })
        .then(async user => {
            if(status !== 400) { 
                await this.setEmail('');
                if(this.passwordResetBackBtn === 'normal') {
                    await this.onRouteChange('signin')
                }
                else {
                    await this.setPasswordResetBackBtn('normal');
                    await this.onRouteChange('userAccount');
                }
            }
            else {
                await this.setState({error: {
                    password1: user.password1,
                    password2: user.password2,
                    general_error: user.general_error
                }});
            }
        })
        .catch(async err => await this.setState({error: {
            email: '',
            password: '',
            name: '',
            general_error: 'Cannot connect to server'
        }}));
    }

    render() {
        return (
            <React.Fragment>
                <article className="br3 ba b--black-10 mv4 mw6 shadow-5 center bg-light-gray">
                    <main className="pa4 black-80">
                        <div className="measure">
                            <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                                <label className="f1 fw6 ph0 mh0 tc">Change Password</label>
                                <div className="mt3">
                                    <label className="db fw6 lh-copy f6" htmlFor="password1">Password</label>
                                    <input 
                                        className="pa2 input-reset ba w-100 tc" 
                                        type="password" 
                                        name="password1"  
                                        id="password1"
                                        minLength="8"
                                        maxLength="255" 
                                        title="8 to 255 characters"
                                        onChange={this.password1_OnChange}
                                        required
                                    />
                                    <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.password1}</label>
                                </div>
                                <div className="mv3">
                                    <label className="db fw6 lh-copy f6" htmlFor="password2">Confirm Password</label>
                                    <input 
                                        className="b pa2 input-reset ba w-100 tc" 
                                        type="password" 
                                        name="password2"  
                                        id="password2"
                                        minLength="8"
                                        maxLength="255" 
                                        title="8 to 255 characters"
                                        onChange={this.password2_OnChange}
                                        required
                                    />
                                    <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.password2}</label>
                                </div>
                            </fieldset>
                            <React.Fragment>
                                <input className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" 
                                    type="submit" 
                                    value="Submit" 
                                    onClick={this.onSubmitNewPassword}
                                />
                            </React.Fragment>
                            <div className='pt3'>
                                <label className='bg-light-yellow dark-red db fw6 lh-copy f6'>{this.state.error.general_error}</label>
                            </div>
                            <div className="lh-copy mt3">
                                {
                                    this.passwordResetBackBtn === 'normal' ?
                                    <p onClick={async () => await this.onRouteChange('signin')} className="f6 link dim black db pointer">Back to sign in</p>
                                    :
                                    <p 
                                        className="f6 link dim black db pointer"
                                        onClick={async () => {
                                            await this.setPasswordResetBackBtn('normal');
                                            await this.onRouteChange('userAccount');
                                        }} 
                                        >Back to User Account
                                    </p>
                                }
                            </div>
                        </div>
                    </main>
                </article>
            </React.Fragment>
        );
    }
}

export default PasswordReset;