import React from 'react';

class EventScroll extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.updateLoadedEventsNumber = this.props.updateLoadedEventsNumber;
        this.scrollMenu = this.props.scrollMenu;
        this.state = {
            windowHeight: window.innerHeight
        }
    }

    handleResize = async (event) => {
        if(this._isMounted) {
            await this.setState({windowHeight: window.innerHeight});
        }
      };

    componentDidMount() {
        this._isMounted = true;
        window.addEventListener("resize", this.handleResize);
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener("resize", this.handleResize);
    }

    handleScroll = async (event) => {
        event.persist()
        
        const target = event.target;
        if(target.scrollHeight - target.scrollTop === target.clientHeight) {
            await this.updateLoadedEventsNumber();
        }
    }

    render() {
        let idName = '';
        if (this.scrollMenu === 'userAccount') {
            idName = 'scroll-inner-content'
        }
        else {
            idName = 'scroll-home'
        }
        return (
            <div id={idName} className='hideScroll' style={{overflowY: 'scroll', height: window.innerHeight-107}} onScroll={this.handleScroll}>
                {this.props.children}
            </div>
        );
    }
}

export default EventScroll