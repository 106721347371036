import React from 'react';
import * as MaterialDesign from 'react-icons/md';

import './Navigation.css';

const Navigation = ({ navBtnState, setSignIn, isSignedIn, onRouteChange, setNavBtnState }) => {
  
  let homeRoute = '';
  let signInRoute = '';
  let createEventRoute = '';
  let userRoute = ''
  let siginBtnTxt = ''
  let attendingEvents = ''
  let fullscreen = true;

  if(isSignedIn === true) {
    homeRoute = 'home';
    userRoute = 'userAccount';
    signInRoute = 'signout';
    createEventRoute = 'createEvent';
    siginBtnTxt = 'Sign Out';
    attendingEvents = 'attendingEvents';
  }
  else {
    homeRoute = 'home';
    userRoute = 'signin';
    signInRoute = 'signin';
    createEventRoute = 'signin';
    siginBtnTxt = 'Sign In';
    attendingEvents = 'signin';
  }


  if (navBtnState === true && window.innerWidth <= 950) {
    fullscreen = false;
  }

  return (
    <React.Fragment>
      {
        fullscreen === true ?
        <div className={navBtnState ? 'sidebar' : 'sidebar isClosed'}>
          <div className={navBtnState ? 'nav' : 'nav isClosed'}>
            <React.Fragment>
              <nav className='grow pointer f1' 
                style={{color: '#F0F8FF'}} 
                onClick={async () => {
                  await onRouteChange(userRoute)
                }}
              >
                  <span style={{fontSize: '6rem'}}><MaterialDesign.MdAccountBox /></span>
              </nav>
            </React.Fragment>
            <div className='pa4'>
              <input className="b f4 link dim br3 ph3 pv2 mb2 dib bg-moon-gray pointer" 
                type="submit" 
                value="Home"
                onClick={async () => await onRouteChange(homeRoute)}
              />
            </div>
            <div className='pa4'>
              <input className="b f4 link dim br3 ph3 pv2 mb2 dib bg-moon-gray pointer" 
                type="submit" 
                value="Create Event" 
                onClick={async () => await onRouteChange(createEventRoute)}
              />
            </div>
            <div className='pa4'>
              <input className="b f4 link dim br3 ph3 pv2 mb2 dib bg-moon-gray pointer" 
                type="submit" 
                value="Attending Events" 
                onClick={async () => await onRouteChange(attendingEvents)}
              />
            </div>
            <div className='pa4'>
              <input className="b f4 link dim br3 ph3 pv2 mb2 dib bg-moon-gray pointer" 
                type="submit" 
                value={siginBtnTxt} 
                onClick={async () => {
                  if (siginBtnTxt === 'Sign Out') {
                    await setSignIn(false);
                    await onRouteChange(signInRoute);
                  }
                  else {
                    await onRouteChange(signInRoute);
                  }
                }}
              /> 
            </div>
          </div>
        </div>
        :
        <div style={{position: 'absolute', zIndex: '2', minWidth: window.innerWidth, minHeight: window.innerHeight}} className={navBtnState ? 'sidebar-small' : 'sidebar-small isClosed'}>
          <div className={navBtnState ? 'nav-small' : 'nav-small isClosed'}>
            <React.Fragment>
              <nav className='grow pointer f1' 
                style={{color: '#F0F8FF'}} 
                onClick={async () => {
                  await onRouteChange(userRoute);
                  await setNavBtnState(false);
                }}
              >
                  <span style={{fontSize: '6rem'}}><MaterialDesign.MdAccountBox /></span>
              </nav>
            </React.Fragment>
            <div className='pa4'>
              <input className="b f4 link dim br3 ph3 pv2 mb2 dib bg-moon-gray pointer" 
                type="submit" 
                value="Home"
                onClick={async () => {
                  await onRouteChange(homeRoute);
                  await setNavBtnState(false);
                }}
              />
            </div>
            <div className='pa4'>
              <input className="b f4 link dim br3 ph3 pv2 mb2 dib bg-moon-gray pointer" 
                type="submit" 
                value="Create Event" 
                onClick={async () => {
                  await onRouteChange(createEventRoute);
                  await setNavBtnState(false);
                }}
              />
            </div>
            <div className='pa4'>
              <input className="b f4 link dim br3 ph3 pv2 mb2 dib bg-moon-gray pointer" 
                type="submit" 
                value="Attending Events" 
                onClick={async () => {
                  await onRouteChange(attendingEvents);
                  await setNavBtnState(false);
                }}
              />
            </div>
            <div className='pa4'>
              <input className="b f4 link dim br3 ph3 pv2 mb2 dib bg-moon-gray pointer" 
                type="submit" 
                value={siginBtnTxt} 
                onClick={async () => {
                  if (siginBtnTxt === 'Sign Out') {
                    await setSignIn(false);
                    await onRouteChange(signInRoute);
                    await setNavBtnState(false);
                  }
                  else {
                    await onRouteChange(signInRoute);
                    await setNavBtnState(false);
                  }
                }}
              /> 
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default Navigation;
