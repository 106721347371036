import React from 'react';
import * as FaIcons from 'react-icons/fa';
import './Navigation.css';

const NavigationBtn = ({ navBtnState, setNavBtnState }) => {

  const showSidebar = async () => await setNavBtnState(!navBtnState);

  return (
    <React.Fragment>
        <div className='navbar'>
          <div className='menu-bars grow pointer f4' style={{ color: '#fff' }}>
            <span style={{fontSize: '2.4rem'}}><FaIcons.FaBars onClick={showSidebar}/></span >
          </div>
        </div>
    </React.Fragment>
  );
}

export default NavigationBtn;